(<template>
  <a-card>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="日期">
              <a-range-picker @change="time" format="YYYY-MM-DD" style="width: 100%">
                <template slot="dateRender" slot-scope="current">
                  <div class="ant-calendar-date" :style="getCurrentStyle(current)">
                    {{ current.date() }}
                  </div>
                </template>
              </a-range-picker>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-button type="primary" @click="loadData">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <h2 v-if="dataSource" style="margin-top: 20px;">资产出租率</h2>
    <table border="0" cellspacing="0" cellpadding="0" v-if="dataSource">
      <tr>
        <th rowspan="2">资产类型</th>
        <th rowspan="2">统计(平方米*天)</th>
        <th colspan="5" style="width: 500px;">南区</th>
        <th colspan="5" style="width: 500px;">北区</th>
        <!--        <th rowspan="2">总计</th>-->
      </tr>
      <tr>
        <td>工厂</td>
        <td>土地</td>
        <td>仓库</td>
        <td>办公</td>
        <th>总计</th>
        <td>工厂</td>
        <td>土地</td>
        <td>仓库</td>
        <td>办公</td>
        <th>总计</th>
      </tr>
      <!--  自营      -->
      <tr>
        <td rowspan="3">自营</td>
        <!--  南区-工厂-自营      -->
        <td>全部</td>
        <td class="text_right">{{ (this.dataSource.used['2']['1']['1'].all * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['2']['2']['1'].all * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['2']['3']['1'].all * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['2']['4']['1'].all * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used.s['2']['1'].all * 0.01).toFixed(2) | numberFormat}}</td>
        <td class="text_right">{{ (this.dataSource.used['1']['1']['1'].all * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['1']['2']['1'].all * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['1']['3']['1'].all * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['1']['4']['1'].all * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used.s['1']['1'].all * 0.01).toFixed(2) | numberFormat}}</td>
      </tr>
      <tr>
        <td>已使用</td>
        <td class="text_right">{{ (this.dataSource.used['2']['1']['1'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['2']['2']['1'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['2']['3']['1'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['2']['4']['1'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used.s['2']['1'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['1']['1']['1'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['1']['2']['1'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['1']['3']['1'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['1']['4']['1'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used.s['1']['1'].used * 0.01).toFixed(2) | numberFormat }}</td>
      </tr>
      <tr class="other_bg">
        <td>比例</td>
        <td class="text_right">{{ this.dataSource.used['2']['1']['1'].used === 0 || this.dataSource.used['2']['1']['1'].all === 0 ? 0 : (this.dataSource.used['2']['1']['1'].used * 100 / this.dataSource.used['2']['1']['1'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used['2']['2']['1'].used === 0 || this.dataSource.used['2']['2']['1'].all === 0 ? 0 : (this.dataSource.used['2']['2']['1'].used * 100 / this.dataSource.used['2']['2']['1'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used['2']['3']['1'].used === 0 || this.dataSource.used['2']['3']['1'].all === 0 ? 0: (this.dataSource.used['2']['3']['1'].used * 100 / this.dataSource.used['2']['3']['1'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used['2']['4']['1'].used === 0 || this.dataSource.used['2']['4']['1'].all === 0 ? 0 : (this.dataSource.used['2']['4']['1'].used * 100 / this.dataSource.used['2']['4']['1'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used.s['2']['1'].used === 0 || this.dataSource.used.s['2']['1'].all === 0 ? 0 : (this.dataSource.used.s['2']['1'].used * 100 / this.dataSource.used.s['2']['1'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used['1']['1']['1'].used === 0 || this.dataSource.used['1']['1']['1'].all === 0 ? 0 : (this.dataSource.used['1']['1']['1'].used * 100 / this.dataSource.used['1']['1']['1'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used['1']['2']['1'].used === 0 || this.dataSource.used['1']['2']['1'].all === 0 ? 0 : (this.dataSource.used['1']['2']['1'].used * 100 / this.dataSource.used['1']['2']['1'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used['1']['3']['1'].used === 0 || this.dataSource.used['1']['3']['1'].all === 0 ? 0: (this.dataSource.used['1']['3']['1'].used * 100 / this.dataSource.used['1']['3']['1'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used['1']['4']['1'].used === 0 || this.dataSource.used['1']['4']['1'].all === 0 ? 0 : (this.dataSource.used['1']['4']['1'].used * 100 / this.dataSource.used['1']['4']['1'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used.s['1']['1'].used === 0 || this.dataSource.used.s['1']['1'].all === 0 ? 0 : (this.dataSource.used.s['1']['1'].used * 100 / this.dataSource.used.s['1']['1'].all).toFixed(2) }}%</td>
      </tr>
      <!--  管委会      -->
      <tr>
        <td rowspan="3">管委会</td>
        <!--  南区-工厂-自营      -->
        <td>全部</td>
        <td class="text_right">{{ (this.dataSource.used['2']['1']['2'].all * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['2']['2']['2'].all * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['2']['3']['2'].all * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['2']['4']['2'].all * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used.s['2']['2'].all * 0.01).toFixed(2) | numberFormat}}</td>
        <td class="text_right">{{ (this.dataSource.used['1']['1']['2'].all * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['1']['2']['2'].all * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['1']['3']['2'].all * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['1']['4']['2'].all * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used.s['1']['2'].all * 0.01).toFixed(2) | numberFormat}}</td>
      </tr>
      <tr>
        <td>已使用</td>
        <td class="text_right">{{ (this.dataSource.used['2']['1']['2'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['2']['2']['2'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['2']['3']['2'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['2']['4']['2'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used.s['2']['2'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['1']['1']['2'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['1']['2']['2'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['1']['3']['2'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['1']['4']['2'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used.s['1']['2'].used * 0.01).toFixed(2) | numberFormat }}</td>
      </tr>
      <tr class="other_bg">
        <td>比例</td>
        <td class="text_right">{{ this.dataSource.used['2']['1']['2'].used === 0 || this.dataSource.used['2']['1']['2'].all === 0 ? 0 : (this.dataSource.used['2']['1']['2'].used * 100 / this.dataSource.used['2']['1']['2'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used['2']['2']['2'].used === 0 || this.dataSource.used['2']['2']['2'].all === 0 ? 0 : (this.dataSource.used['2']['2']['2'].used * 100 / this.dataSource.used['2']['2']['2'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used['2']['3']['2'].used === 0 || this.dataSource.used['2']['3']['2'].all === 0 ? 0: (this.dataSource.used['2']['3']['2'].used * 100 / this.dataSource.used['2']['3']['2'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used['2']['4']['2'].used === 0 || this.dataSource.used['2']['4']['2'].all === 0 ? 0 : (this.dataSource.used['2']['4']['2'].used * 100 / this.dataSource.used['2']['4']['2'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used.s['2']['2'].used === 0 || this.dataSource.used.s['2']['2'].all === 0 ? 0 : (this.dataSource.used.s['2']['2'].used * 100 / this.dataSource.used.s['2']['2'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used['1']['1']['2'].used === 0 || this.dataSource.used['1']['1']['2'].all === 0 ? 0 : (this.dataSource.used['1']['1']['2'].used * 100 / this.dataSource.used['1']['1']['2'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used['1']['2']['2'].used === 0 || this.dataSource.used['1']['2']['2'].all === 0 ? 0 : (this.dataSource.used['1']['2']['2'].used * 100 / this.dataSource.used['1']['2']['2'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used['1']['3']['2'].used === 0 || this.dataSource.used['1']['3']['2'].all === 0 ? 0: (this.dataSource.used['1']['3']['2'].used * 100 / this.dataSource.used['1']['3']['2'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used['1']['4']['2'].used === 0 || this.dataSource.used['1']['4']['2'].all === 0 ? 0 : (this.dataSource.used['1']['4']['2'].used * 100 / this.dataSource.used['1']['4']['2'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used.s['1']['2'].used === 0 || this.dataSource.used.s['1']['2'].all === 0 ? 0 : (this.dataSource.used.s['1']['2'].used * 100 / this.dataSource.used.s['1']['2'].all).toFixed(2) }}%</td>
      </tr>
      <!--  济高      -->
      <tr>
        <td rowspan="3">济高</td>
        <!--  南区-工厂-自营      -->
        <td>全部</td>
        <td class="text_right">{{ (this.dataSource.used['2']['1']['3'].all * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['2']['2']['3'].all * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['2']['3']['3'].all * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['2']['4']['3'].all * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used.s['2']['3'].all * 0.01).toFixed(2) | numberFormat}}</td>
        <td class="text_right">{{ (this.dataSource.used['1']['1']['3'].all * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['1']['2']['3'].all * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['1']['3']['3'].all * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['1']['4']['3'].all * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used.s['1']['3'].all * 0.01).toFixed(2) | numberFormat}}</td>
      </tr>
      <tr>
        <td>已使用</td>
        <td class="text_right">{{ (this.dataSource.used['2']['1']['3'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['2']['2']['3'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['2']['3']['3'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['2']['4']['3'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used.s['2']['3'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['1']['1']['3'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['1']['2']['3'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['1']['3']['3'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used['1']['4']['3'].used * 0.01).toFixed(2) | numberFormat }}</td>
        <td class="text_right">{{ (this.dataSource.used.s['1']['3'].used * 0.01).toFixed(2) | numberFormat }}</td>
      </tr>
      <tr class="other_bg">
        <td>比例</td>
        <td class="text_right">{{ this.dataSource.used['2']['1']['3'].used === 0 || this.dataSource.used['2']['1']['3'].all === 0 ? 0 : (this.dataSource.used['2']['1']['3'].used * 100 / this.dataSource.used['2']['1']['3'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used['2']['2']['3'].used === 0 || this.dataSource.used['2']['2']['3'].all === 0 ? 0 : (this.dataSource.used['2']['2']['3'].used * 100 / this.dataSource.used['2']['2']['3'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used['2']['3']['3'].used === 0 || this.dataSource.used['2']['3']['3'].all === 0 ? 0: (this.dataSource.used['2']['3']['3'].used * 100 / this.dataSource.used['2']['3']['3'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used['2']['4']['3'].used === 0 || this.dataSource.used['2']['4']['3'].all === 0 ? 0 : (this.dataSource.used['2']['4']['3'].used * 100 / this.dataSource.used['2']['4']['3'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used.s['2']['3'].used === 0 || this.dataSource.used.s['2']['3'].all === 0 ? 0 : (this.dataSource.used.s['2']['3'].used * 100 / this.dataSource.used.s['2']['3'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used['1']['1']['3'].used === 0 || this.dataSource.used['1']['1']['3'].all === 0 ? 0 : (this.dataSource.used['1']['1']['3'].used * 100 / this.dataSource.used['1']['1']['3'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used['1']['2']['3'].used === 0 || this.dataSource.used['1']['2']['3'].all === 0 ? 0 : (this.dataSource.used['1']['2']['3'].used * 100 / this.dataSource.used['1']['2']['3'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used['1']['3']['3'].used === 0 || this.dataSource.used['1']['3']['3'].all === 0 ? 0: (this.dataSource.used['1']['3']['3'].used * 100 / this.dataSource.used['1']['3']['3'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used['1']['4']['3'].used === 0 || this.dataSource.used['1']['4']['3'].all === 0 ? 0 : (this.dataSource.used['1']['4']['3'].used * 100 / this.dataSource.used['1']['4']['3'].all).toFixed(2) }}%</td>
        <td class="text_right">{{ this.dataSource.used.s['1']['3'].used === 0 || this.dataSource.used.s['1']['3'].all === 0 ? 0 : (this.dataSource.used.s['1']['3'].used * 100 / this.dataSource.used.s['1']['3'].all).toFixed(2) }}%</td>
      </tr>
      <!--  总计      -->
      <tr>
        <td rowspan="3">总计</td>
        <!--  南区-工厂-自营      -->
        <td>全部</td>
        <td colspan="10">{{ (this.dataSource.used.s.all.all * 0.01).toFixed(2) | numberFormat }}</td>
      </tr>
      <tr>
        <td>已使用</td>
        <td colspan="10">{{ (this.dataSource.used.s.all.used * 0.01).toFixed(2) | numberFormat }}</td>
      </tr>
      <tr class="other_bg">
        <td>比例</td>
        <td colspan="10">{{ this.dataSource.used.s.all.used === 0 || this.dataSource.used.s.all.all === 0 ? 0 : (this.dataSource.used.s.all.used * 100 / this.dataSource.used.s.all.all).toFixed(2) }}%</td>
      </tr>
    </table>
  </a-card>
</template>

<script>
import {
  asset_rental_rate_summary_statistics
} from '@/api/property_bill'
import moment from 'moment/moment'

export default {
  name: 'TableList',
  components: {},
  data () {
    return {
      dataSource: undefined,
      // 查询参数
      queryParam: {}
    }
  },
  methods: {
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.queryParam.start_date = dateStrings[0]
      this.queryParam.end_date = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    loadData () {
      if (this.queryParam.start_date === undefined || this.queryParam.end_date === '') {
        this.$message.warning('请选择日期')
        return
      }
      asset_rental_rate_summary_statistics(this.queryParam)
        .then(res => {
          console.log(res.data, '我是数据列表@')
          this.dataSource = res.data
        })
    },
    disabledDate (current) {
      return current && current < moment('2023-01-01')
    }
  }
}
</script>
<style scoped>
table {
  table-layout: fixed;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

th, td {
  border-right: 1px solid #ddd;
  border-bottom: 0.5px solid #ddd;
  vertical-align: middle;
  text-align: center;
  padding: 12px 0;
  color: #666;
  font-size: 14px;
  width: 120px;
}

th {
  font-weight: normal;
}

.text_right {
  text-align: right;
  padding-right: 10px;
}

.other_bg {
  background-color: #f5f5f5
}

</style>
